// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-club-calculator-js": () => import("./../../src/pages/club-calculator.js" /* webpackChunkName: "component---src-pages-club-calculator-js" */),
  "component---src-pages-coach-portal-js": () => import("./../../src/pages/coach-portal.js" /* webpackChunkName: "component---src-pages-coach-portal-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-coppa-js": () => import("./../../src/pages/coppa.js" /* webpackChunkName: "component---src-pages-coppa-js" */),
  "component---src-pages-faq-js": () => import("./../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parent-portal-js": () => import("./../../src/pages/parent-portal.js" /* webpackChunkName: "component---src-pages-parent-portal-js" */),
  "component---src-pages-pricing-js": () => import("./../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-blog-listing-js": () => import("./../../src/templates/blogListing.js" /* webpackChunkName: "component---src-templates-blog-listing-js" */),
  "component---src-templates-blog-post-js": () => import("./../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-faq-category-js": () => import("./../../src/templates/faqCategory.js" /* webpackChunkName: "component---src-templates-faq-category-js" */),
  "component---src-templates-faq-question-js": () => import("./../../src/templates/faqQuestion.js" /* webpackChunkName: "component---src-templates-faq-question-js" */),
  "component---src-templates-landing-page-referral-js": () => import("./../../src/templates/landingPageReferral.js" /* webpackChunkName: "component---src-templates-landing-page-referral-js" */)
}

