import { rem } from "polished";

const theme = {
  // COLOURS
  white: `#ffffff`,
  blue: `#0088E9`,
  blueDark: `#12253C`,
  grey: `#EBEBEB`,
  greyLightest: `#F9F9F9`,
  greyLight: `#999999`,
  greyDark: `#666666`,
  greyDarkFaded: `rgba(102, 102, 102, 0.5)`,
  green: `#30B842`,
  greenDark: `#2FA73D`,
  purple: `#6529CC`,
  purpleLight: `#9B51FF`,
  purpleDark: `#8541EB`,
  yellow: `#F7A600`,
  red: `#F23F2D`,
  pink: `#D9347E`,
  orange: `#F46A01`,
  orangeDark: `#E86501`,

  // FONTS 
  headingFont: `'display-lucky', 'Helvetica', sans-serif`,
  baseFont: `'regular-lucky', 'Helvetica', sans-serif`,

  // Sizes (base 18px)
  // Conversion: https://www.ninjaunits.com/converters/pixels/pixels-rem/
  rem48: `2.6666666666666665rem`, // 48px
  rem40: `2.2222222222222223rem`, // 40px
  rem30: `1.6666666666666667rem`, // 30px
  rem28: `1.5555555555555556rem`, // 28px
  rem24: `1.3333333333333333rem`, // 24px
  rem22: `1.2222222222222223rem`, // 22px
  rem20: `1.1111111111111112rem`, // 20px
  rem18: `1rem`, // 18px
  rem16: `0.8888888888888888rem`, // 16px
  rem12: `0.6666666666666666rem`, // 12px
  rem8: `0.4444444444444444rem`, // 8px

  // BORDER-RADIUS
  borderRadiusSqBtn: `3px`,
  borderRadiusRndBtn: `30px`,
  borderRadiusContent: `10px`,

  // BREAKPOINTS
  screenXS: `375px`,
  screenS: `600px`,
  screenM: `800px`,
  screenL: `1024px`,
  screenXL: `1200px`,
  screenXXL: `1600px`,
  // Breakpoints array for Rebass (https://github.com/rebassjs/grid#theming)
  breakpoints: [`600px`, `800px`, `1024px`, `1200px`, `1400px`],

  maxContentWidth: `1200px`,

  btnPadS: `10px 20px`,
  btnPadL: `14px 20px`,

  // SPACE
  space6Point: [6, 12, 18, 24, 30, 36],
  space8Point: [8, 16, 24, 32, 40, 48],

  // TRANSITIONS
  transitionDefault: `all 0.25s ease`,
  contentFade: `all .6s`,
};

export default theme;
