/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const fonts = require(`./css/fonts.css`);

const { ThemeProvider } = require(`styled-components`);
const React = require(`react`);

const theme = require(`./src/utils/theme`).default;

// Any changes here need to be mirrored in gatsby-ssr.js
exports.wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>;
};

// polyfill for smoothscroll on browsers that dont have it natively
const smoothscroll = require(`smoothscroll-polyfill`);
smoothscroll.polyfill();